import { BinaryResponse, BlobOptions } from './api.model';
import { ManyResult, RangeUtils } from './api.model';

import { ENVIRONMENT, EnvironmentConfig } from '@abstractions';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, take, tap } from 'rxjs/operators';

import { FileNameBuilder } from './download-file/file.model';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService extends HttpClient {
  static readonly filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  static readonly defaultFileName = FileNameBuilder.pdf('document');

  private readonly envConfig: EnvironmentConfig = inject(ENVIRONMENT);
  private sessionId: string | null = null; // FIXME

  static mapManyResult<T>(arg: HttpResponse<T[]>): ManyResult<T> {
    const contentRange = arg.headers.get('content-range');
    if (!contentRange) return { data: arg.body, size: arg.body?.length } as ManyResult<T>;
    let fields = contentRange.split('/');
    const range = fields[0];
    const count = fields[1];

    return {
      data: arg.body,
      size: arg.body?.length ?? 0,
      total: +count || null,
      range: RangeUtils.fromHeader(range),
    };
  }

  downloadFile(
    url: string,
    fileName: string | undefined,
    throwIf = (httpResponse: HttpResponse<any>) => {},
  ): Observable<void> {
    const opt: BlobOptions = {
      observe: 'response',
      responseType: 'blob',
    };
    return this.get(url, opt).pipe(
      map((res) => {
        throwIf(res);
        return this.extractContent(res, fileName ? fileName : this.mapFilenameFromHeader(res.headers));
      }),
    );
  }

  downloadFileAsync(url: string, fileIds: string[], fileName: string): Observable<any> {
    const opt: BlobOptions = {
      observe: 'response',
      responseType: 'blob',
    };

    return this.post(url, fileIds, opt).pipe(
      take(1),
      tap((r) => {
        this.extractContent(r, fileName);
      }),
    );
  }

  setSessionId(sessionId: string | null): void {
    this.sessionId = sessionId;
  }

  fetchBinary(url: string, mime: string): Observable<BinaryResponse> {
    // FIXME
    const opt: BlobOptions = {
      observe: 'response',
      responseType: 'blob',
    };
    return this.get(url, opt).pipe(
      map((res: HttpResponse<Blob>) => {
        if (this.envConfig.environment === 'prod' && (res.status === 204 || !res.body)) throw new Error('No content');

        let fileName: string;

        const fileType = res.headers.get('content-type')?.split(';')[0];
        try {
          fileName = this.mapFilenameFromHeader(res.headers);
        } catch (err) {
          fileName = ApiService.defaultFileName;
        }
        return {
          blob: res.body ? new Blob([res.body], { type: fileType }) : null,
          fileName,
          fileType: fileType ?? '',
        };
      }),
    );
  }

  mapFilenameFromHeader(headers: HttpHeaders): string {
    let fileName = null;
    const matches = ApiService.filenameRegex.exec(headers.get('content-disposition') ?? '');
    if (matches != null && matches[1]) {
      fileName = decodeURIComponent(matches[1].replace(/['"]/g, '').replace('+', ' '));
      if (fileName.substr(0, 5) === 'UTF-8') {
        fileName = fileName.substr(5, fileName.length);
      }
    }
    return fileName ?? '';
  }

  private extractContent(res: HttpResponse<Blob>, fileName: string): void {
    const a = window.document.createElement('a');
    const blob = res.body;
    a.download = fileName;
    if (blob) {
      a.href = window.URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.click();
    }

    document.body.removeChild(a);
  }
}
